import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import Cookies from "js-cookie";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ProjectIcon from "@mui/icons-material/Code"; // Icon for projects
import TrainingIcon from "@mui/icons-material/School"; // Icon for trainings
import InternshipIcon from "@mui/icons-material/Work"; // Icon for internships
import PublicationIcon from "@mui/icons-material/Description"; // Icon for publications
import AssignmentIcon from "@mui/icons-material/Assignment"; // Icon for assignments
import DissertationIcon from "@mui/icons-material/LibraryBooks"; // Icon for dissertations
import HomeIcon from "@mui/icons-material/Home"; // Icon for home link
import LinkIcon from "@mui/icons-material/Link"; // Icon for external links
import logo from "../../assets/images/logopng.png";
import { Link } from "react-router-dom";
import VisitorTracker from "../common/visitorTracker";

function Footer() {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#092e52",
                    color: "#fff",
                    padding: "40px 0",
                    mt: "auto",
                }}
            >
                <VisitorTracker />
                <Container maxWidth='lg'>
                    <Grid container spacing={5}>
                        {/* About Section */}
                        <Grid item xs={12} sm={3} sx={{ display: "flex", gap: 2, alignItems: "center", flexDirection: { xs: "row", sm: "column" } }}>
                            <Box
                                mb={2}
                                component='img'
                                sx={{
                                    height: 60,
                                    width: 170,
                                }}
                                src={logo}
                            />
                            <Typography variant='body1' color='' lineHeight={1.75}>
                                Our goal is to provide high-quality, affordable academic solutions to help students, researchers, and professionals achieve
                                their goals.
                            </Typography>
                        </Grid>

                        {/* Services Section */}
                        <Grid item xs={6} sm={3}>
                            <Typography variant='h6' gutterBottom sx={{ borderBottom: "2px solid #aaa", paddingBottom: "10px" }}>
                                Services
                            </Typography>
                            <Typography mb={1.5} variant='body1' sx={{ display: "flex", alignItems: "center" }}>
                                <ProjectIcon sx={{ mr: 1, fontSize: 20 }} />
                                Projects
                            </Typography>
                            <Typography mb={1.5} variant='body1' sx={{ display: "flex", alignItems: "center" }}>
                                <TrainingIcon sx={{ mr: 1, fontSize: 20 }} />
                                Trainings
                            </Typography>
                            <Typography mb={1.5} variant='body1' sx={{ display: "flex", alignItems: "center" }}>
                                <InternshipIcon sx={{ mr: 1, fontSize: 20 }} />
                                Internships
                            </Typography>
                            <Typography mb={1.5} variant='body1' sx={{ display: "flex", alignItems: "center" }}>
                                <PublicationIcon sx={{ mr: 1, fontSize: 20 }} />
                                Publications
                            </Typography>
                            <Typography mb={1.5} variant='body1' sx={{ display: "flex", alignItems: "center" }}>
                                <AssignmentIcon sx={{ mr: 1, fontSize: 20 }} />
                                Assignments
                            </Typography>
                            <Typography mb={1.5} variant='body1' sx={{ display: "flex", alignItems: "center" }}>
                                <DissertationIcon sx={{ mr: 1, fontSize: 20 }} />
                                Dissertations
                            </Typography>
                        </Grid>

                        {/* Quick Links Section */}
                        <Grid item xs={6} sm={3}>
                            <Typography variant='h6' gutterBottom sx={{ borderBottom: "2px solid #aaa", paddingBottom: "10px" }}>
                                Quick Links
                            </Typography>
                            <Box mb={1.5} sx={{ display: "flex", alignItems: "center" }}>
                                <HomeIcon sx={{ mr: 1, fontSize: 20 }} />
                                <Link style={{ color: "white", textDecoration: "none" }} to='/'>
                                    Home
                                </Link>
                            </Box>
                            <Box mb={1.5} sx={{ display: "flex", alignItems: "center" }}>
                                <ProjectIcon sx={{ mr: 1, fontSize: 20 }} />
                                <Link style={{ color: "white", textDecoration: "none" }} to='/projects'>
                                    Projects
                                </Link>
                            </Box>
                            <Box mb={1.5} sx={{ display: "flex", alignItems: "center" }}>
                                <LinkIcon sx={{ mr: 1, fontSize: 20 }} />
                                <Link style={{ color: "white", textDecoration: "none" }} to='/about-us'>
                                    About Us
                                </Link>
                            </Box>
                            <Box mb={1.5} sx={{ display: "flex", alignItems: "center" }}>
                                <LinkIcon sx={{ mr: 1, fontSize: 20 }} />
                                <Link style={{ color: "white", textDecoration: "none" }} to='/contact-us'>
                                    Contact Us
                                </Link>
                            </Box>
                        </Grid>

                        {/* Contact Section */}
                        <Grid item xs={12} sm={3}>
                            <Typography variant='h6' gutterBottom sx={{ borderBottom: "2px solid #aaa", paddingBottom: "10px" }}>
                                Contact Us
                            </Typography>
                            <Typography variant='body1' sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                <PhoneIcon sx={{ fontSize: 20, mr: 1, color: "white" }} />
                                +91 91331 81444
                            </Typography>
                            <Typography variant='body1' sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                <LanguageIcon sx={{ fontSize: 20, mr: 1, color: "white" }} />
                                www.techlogics.co.in
                            </Typography>
                            <Typography variant='body1' sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                <EmailIcon sx={{ fontSize: 20, mr: 1, color: "white" }} />
                                techlogics39@gmail.com
                            </Typography>
                            <Typography variant='body1' sx={{ display: "flex", alignItems: "center" }}>
                                <LocationOnIcon sx={{ fontSize: 20, mr: 1, color: "white" }} />
                                Road No: 3, Kamala Nivas, VR Colony, Kamala Nagar, Dilsuknagar, Hyderabad, Telangana 500060
                            </Typography>
                            <Typography variant='body1' sx={{ display: "flex", alignItems: "center" }}>
                                <LocationOnIcon sx={{ fontSize: 20, mr: 1, color: "white" }} />
                                39-9-47, SVS Temple Street, MG Rd, Labbipet, Vijayawada, Andhra Pradesh 520010
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box textAlign='center' pt={3} pb={3} sx={{ backgroundColor: "#000305" }}>
                <Typography variant='body1' color='white'>
                    © {new Date().getFullYear()} Copyright. All rights reserved.
                </Typography>
                <Typography variant='body1' color='white'>
                    Designed and Developed by Tech Logics
                </Typography>
            </Box>
        </>
    );
}

export default Footer;
